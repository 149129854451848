import * as Sentry from "@sentry/vue";
import type { Pinia } from "pinia";
import type { App } from "vue";

// https://docs.sentry.io/platforms/javascript/guides/vue/features/
export function createSentry(
	app: App,
	options: {
		pinia?: Pinia;
	} = {},
) {
	console.log("env", import.meta.env);

	Sentry.init({
		app,
		dsn: "https://f4aa5f8b29d75691b1d9346f7cc82293@o224998.ingest.us.sentry.io/4508690851168256",
		environment: import.meta.env.MODE,
		integrations: [
			// Sentry.browserTracingIntegration({ router }),
			// Sentry.replayIntegration(),
		],
		// // Tracing
		// tracesSampleRate: 1.0, //  Capture 100% of the transactions
		// // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
		// tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
		// // Session Replay
		// replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
		// replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	});

	if (options.pinia) options.pinia.use(Sentry.createSentryPiniaPlugin());
}

export function setSentryTag(key: string, value: string | number | undefined) {
	Sentry.setTag(key, value);
}

export function captureError(message: string, error: unknown) {
	console.error(message, error);
	Sentry.captureException(error);
}
