export const routes = [
  {
    path: '/',
    name: '/',
    component: () => import('/build/src/pages/index.vue'),
    /* no children */
  },
  {
    path: '/:displayId',
    /* internal name: '/[displayId]' */
    /* no component */
    children: [
      {
        path: 'embed',
        name: '/[displayId]/embed',
        component: () => import('/build/src/pages/[displayId]/embed.vue'),
        /* no children */
      },
      {
        path: 'preview',
        name: '/[displayId]/preview',
        component: () => import('/build/src/pages/[displayId]/preview.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/preview',
    /* internal name: '/preview' */
    /* no component */
    children: [
      {
        path: ':displayId',
        name: '/preview/[displayId]',
        component: () => import('/build/src/pages/preview/[displayId].vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/setup',
    name: '/setup',
    component: () => import('/build/src/pages/setup.vue'),
    /* no children */
  }
]

export function handleHotUpdate(_router, _hotUpdateCallback) {
  if (import.meta.hot) {
    import.meta.hot.data.router = _router
    import.meta.hot.data.router_hotUpdateCallback = _hotUpdateCallback
  }
}

if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    if (!router) {
      import.meta.hot.invalidate('[unplugin-vue-router:HMR] Cannot replace the routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    for (const route of mod.routes) {
      router.addRoute(route)
    }
    // call the hotUpdateCallback for custom updates
    import.meta.hot.data.router_hotUpdateCallback?.(mod.routes)
    const route = router.currentRoute.value
    router.replace({
      ...route,
      // NOTE: we should be able to just do ...route but the router
      // currently skips resolving and can give errors with renamed routes
      // so we explicitly set remove matched and name
      name: undefined,
      matched: undefined,
      force: true
    })
  })
}

